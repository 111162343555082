<template>
  <section>
    <aRow v-if="room.roomId" class="messages">
      <aCol class="room-details" :span="24">
        <aRow type="flex" align="middle" :gutter="20">
          <aCol class="rooms-switch" flex="32px">
            <a-icon
              v-if="showRooms"
              type="arrow-left"
              @click="onClickCollapseRooms(false)"
            />
            <a-icon
              v-if="!showRooms"
              type="arrow-right"
              @click="onClickCollapseRooms(true)"
            />
          </aCol>

          <aCol flex="50px">
            <a-avatar icon="user" :src="room.avatar" :size="50" />
          </aCol>

          <aCol class="details" flex="auto">
            <div class="name">{{ room.roomName }}</div>
            <div class="users">
              <div v-if="room.typingUsers.length">
                {{ formatTypingUsers(room.users, room.typingUsers) }}
              </div>
              <div v-else>
                {{ room.users | formatRoomUsers }}
              </div>
            </div>
          </aCol>

          <aCol class="actions" flex="32px">
            <!-- <a-icon type="more" /> -->
          </aCol>
        </aRow>
      </aCol>

      <aCol
        ref="messagesList"
        class="messages-list"
        :span="24"
        @scroll="updateScrollHeight"
      >
        <aRow>
          <aCol v-for="(message, index) in messages" :key="index" :span="24">
            <MessageBallon
              :message="message"
              :messages="messages"
              :index="index"
              :currentUserId="currentUserId"
              :showMessagesAvatar="showMessagesAvatar"
              :activeAction="activeAction"
              @onClickChangeActiveAction="onClickChangeActiveAction"
              @onClickRespondMessage="onClickRespondMessage"
            />
          </aCol>
        </aRow>
      </aCol>

      <aCol class="messages-actions" :span="24">
        <div class="reply-to" v-if="messageToReply.content">
          <aRow class="reply-message" type="flex" justify="space-between">
            <aCol>
              <div>
                <div class="name">{{ messageToReply.username }}</div>
                <div class="content">{{ messageToReply.content }}</div>
              </div>
            </aCol>
            <aCol>
              <a-icon type="close-circle" @click="messageToReply = {}" />
            </aCol>
          </aRow>
        </div>
        <aRow type="flex" align="middle">
          <aCol class="emoji ico" flex="40px">
            <a-popover v-model="visible" trigger="click">
              <div slot="content" style="padding: 0">
                <VEmojiPicker
                  :emojiSize="25"
                  :limitFrequently="14"
                  :emojisByRow="10"
                  :showSearch="false"
                  @select="selectEmoji"
                />
              </div>
              <span @click="visible = true">
                <a-icon type="smile" />
              </span>
            </a-popover>
          </aCol>

          <aCol class="file ico" flex="40px">
            <a-icon type="paper-clip" style="opacity: 0.3" />
          </aCol>

          <aCol class="type-area" flex="auto">
            <a-input
              placeholder="Mensagem"
              v-model="message"
              @pressEnter="onPressEnterSendMessage"
              @change="userIsTyping"
              allowClear
            >
            </a-input>
          </aCol>

          <aCol class="send ico" flex="50px">
            <!-- <a-icon v-if="!message" class="audio" type="audio" /> -->
            <a-icon
              v-if="!message"
              class="text"
              type="arrow-right"
              style="opacity: 0.3; cursor: inherit"
            />
            <span v-if="message" @click="onPressEnterSendMessage">
              <a-icon class="text" type="arrow-right" />
            </span>
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import MessageBallon from "./MessageBallon.vue";

export default {
  name: "ChatMessages",
  props: {
    messages: Array,
    room: Object,
    currentUserId: String,
    showMessagesAvatar: Boolean,
    loadedAllMessages: Boolean,
  },
  components: { VEmojiPicker, MessageBallon },
  data() {
    return {
      showRooms: true,
      visible: false,
      message: "",
      activeAction: "",
      messageToReply: {},
    };
  },
  mounted() {
    this.scrollToBottom();
  },
  filters: {
    formatRoomUsers(arr) {
      const areAllEqual = arr.every(
        (value) => value.username === arr[0].username
      );

      let users = "";

      if (areAllEqual) {
        users = "Você  ";
      } else {
        arr.forEach((user) => {
          users += `${user.username}, `;
        });
      }

      return users.slice(0, -2);
    },
  },
  methods: {
    formatTypingUsers(users, typingUsers) {
      const areAllEqual = users.every(
        (value) => value.username === users[0].username
      );

      let usersTyping = "";

      if (!areAllEqual) {
        users.forEach((user) => {
          if (typingUsers.includes(user._id)) {
            usersTyping += `${user.username} está digitando...  `;
          }
        });
      }

      return usersTyping.slice(0, -2);
    },
    onClickChangeActiveAction(id) {
      this.activeAction = this.activeAction === id ? "" : id;
    },
    onClickRespondMessage(message) {
      this.messageToReply = message;
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = this.$refs.messagesList;
        container.$el.scrollTop = container.$el.scrollHeight;
      }, 200);
    },
    onClickCollapseRooms(show) {
      this.showRooms = show;
      this.$emit("onClickCollapseRooms", show);
    },
    onPressEnterSendMessage() {
      this.$emit(
        "onPressEnterSendMessage",
        this.message,
        this.room,
        this.messageToReply
      );
      this.scrollToBottom();
      this.message = "";
      this.messageToReply = {};
    },
    userIsTyping() {
      this.$emit("userIsTyping", this.message, this.room);
    },
    updateScrollHeight(e) {
      if (e.target.scrollTop === 0) {
        if (!this.loadedAllMessages) e.target.scrollTop = 50;
        this.$emit("onScrollLoadMoreMessages");
      }
    },
    selectEmoji(emoji) {
      this.message += emoji.data;
    },
    hide() {
      console.log(111);
      this.visible = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.messages
  .messages-actions
    position: relative
    border-top: 1px solid #eee
    padding: 10px 15px
    background: #FFF
    .emoji
      i
        cursor: pointer
    .reply-to
      position: absolute
      left: 0
      bottom: 50px
      background: #fff
      right: 0
      padding: 6px 6px 0
      border-top: 1px solid #eee
      .reply-message
        background: #fafafa
        border: 1px solid #ddd
        padding: 9px
        border-radius: 6px
        border-left: 4px solid #ddd
        line-height: 1.1
        .content
          font-size: 12px
        .name
          font-size: 11px
          font-weight: 600
    .ico
      font-size: 18px
    .send
      text-align: center
      i
        cursor: pointer
  .messages-list
    padding: 10px
    overflow: auto
    height: 500px
    scroll-behavior: smooth
  .room-details
    border-bottom: 1px solid #eee
    padding: 7px 15px 6px
    background: #FFF
    .rooms-switch
      i
        cursor: pointer
        font-size: 20px
        color: #333
    .actions
      color: #333
      font-size: 30px
      cursor: pointer
    .details
      .users
        font-size: 10px
      .name
        font-size: 16px
        font-weight: 600
        color: #333
</style>
